import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import HamburgerMenu from './HamburgerMenu'; // Import the HamburgerMenu component

export default function Header() {
  const navigate = useNavigate();

  // State to control the visibility of the Services dropdown
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleBookNowClick = () => {
    window.location.href = "https://aestushealth.janeapp.com/#staff_member/1";
  };

  return (
    <header className="header">
      <div className="logo">
        <a href="https://aestushealth.com">
          <img src="/logo.png" alt="Aestus Logo" />
        </a>
        <div className="logo-text">
          <h1>Aestus</h1>
          
          <p>Counselling and Psychotherapy</p>
        </div>
      </div>

      {/* Regular Navigation Links for desktop */}
      <nav className="nav-links">
        <ul>
          <li><a href="/#home">Home</a></li>
          <li><a href="/contact">Contact</a></li>

          {/* Dropdown for Services */}
          <li
            className="dropdown"
            onMouseEnter={() => setIsDropdownOpen(true)}
            onMouseLeave={() => setIsDropdownOpen(false)}
          >
            <a href="/services" className="dropbtn">
              Services
            </a>
            {/* Conditional rendering for the dropdown content */}
            {isDropdownOpen && (
              <ul className="dropdown-content">
      <li><a href="/angerManagementTherapy">Anger Management Therapy</a></li>          
      <li><a href="/anxiety">Anxiety Therapy</a></li>
      <li><a href="/borderlinepersonalitydisorder">Borderline Personality Disorder Therapy</a></li>
      <li><a href="/ChildrenTeenTherapy">Children and Teen Therapy</a></li>
      <li><a href="/Depression">Depression Therapy</a></li>
      <li><a href="/Grief">Grief Therapy</a></li>
      <li><a href="/trauma">Trauma Therapy</a></li>
      
              </ul>
            )}
          </li>

          <li><a href="/faq">FAQ</a></li>
        <li><a href="/Blog">Blog</a></li>


        </ul>
      </nav>

      {/* Hamburger Menu for mobile */}
      <HamburgerMenu />

      {/* Floating "Book Now" button */}
      <button className="floating-book-now" onClick={handleBookNowClick}>
        Book Now
      </button>
    </header>
  );
}
