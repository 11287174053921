const blogPosts = [
  {
    id: 1,
    title: "3 Myths about Self-Compassion",
    slug: "3-myths-about-self-compassion",
    excerpt: "Over the year and a half I have been providing therapy, one thing I’ve learned somewhat unexpectedly is that while we find it easy to have compassion for others, we find it much more difficult to have compassion for ourselves.",
    image: "/self-compassion.png",
    date: "October 6, 2024",
    author: "Ali Muhammad",
    content: [
      { 
        type: "text",
        value:
          "<em>I recommend the website <a href='https://self-compassion.org/' target='_blank'>Self-Compassion.org</a>, where I got much of the inspiration for this blog post, for resources and exercises for cultivating self-compassion.</em>"
      },
      { 
        type: "text", 
        value: "Over the year and a half I have been providing therapy, one thing I’ve learned somewhat unexpectedly is that while we find it easy to have compassion for others, we find it much more difficult to have compassion for ourselves. Though there are several reasons that this may be the case, such as traumatic events and feelings of unworthiness, one recurring theme I have found in my practice is that the benefits of self-compassion tend to be counterintuitive. For this reason, I wanted to dedicate my first blog to dispelling some of the myths about self-compassion." 
      },
      {
        type: "text", 
        value: 'Let’s start by defining what self-compassion is: <em>"Self-compassion means being kind and supportive towards ourselves when we suffer or make mistakes. It\'s a warm and caring attitude that wants to help, not harm. To experience self-compassion, we must be willing to face our pain and discomfort with understanding and acceptance"</em> (Neff, 2023).'
      },
      
      
      { 
        type: "text", 
        value: "One thing to note about this definition is that it’s not simply about being gentle and kind towards oneself, but involves the courage to acknowledge and confront one’s pain and discomfort. Self-compassion is often mistaken for being self-indulgent or trying to avoid challenging emotions. However, in reality, it is about facing challenging emotions head-on. This brings me to the first, and one of the most common myths about self-compassion:" 
      },
      { 
        type: "heading", 
        value: "<strong>Myth 1: Self-compassion allows us to avoid accountability</strong>" 
      },
      { 
        type: "image",
        src: "/self-compassion1.jpg",
        alt: "Self-compassion image 1"
      },
      { 
        type: "text", 
        value: "This is a somewhat understandable misperception. If self-compassion is about not judging ourselves harshly, it is easy to think that it involves avoiding accountability. However, accepting one’s mistakes and not judging oneself harshly aren’t mutually exclusive. Let me explain using an example." 
      },
      { 
        type: "text", 
        value: "Imagine you make a mistake at your job and miss a deadline. Let’s say your boss is very harsh and judgmental when it comes to mistakes. When you tell them you missed the deadline, they yell and tell you how incompetent you are. For most people, such an experience would be demoralizing and result in a loss of confidence. You might become defensive or try to hide future mistakes." 
      },
      { 
        type: "text", 
        value: "Now, imagine a different scenario where your boss is understanding and kind. They still acknowledge your mistake, but instead of berating you, they ask how you can avoid making the same mistake in the future and work with you to take corrective action. This supportive approach would likely leave you motivated to improve, rather than feeling demoralized." 
      },
      { 
        type: "text", 
        value: "Similarly, when we are compassionate with ourselves, we are more likely to acknowledge our mistakes and feel motivated to improve. Research supports this: self-compassion appears to increase motivation (Suh & Chong, 2022) and enhances our ability to learn from failure (Miyagawa & Taniguchi, 2022). Self-compassion and accountability can coexist." 
      },
      { 
        type: "heading", 
        value: "<strong>Myth 2: Self-compassion is selfish</strong>" 
      },
      { 
        type: "image",
        src: "/self-compassion2.jpg",
        alt: "Self-compassion image 2"
      },
      { 
        type: "text", 
        value: "Another common misconception is that self-compassion is selfish or self-indulgent. Some believe that focusing on one's own well-being detracts from helping others. However, research shows that self-compassion increases our capacity to connect with others (Lathren et al., 2021), improves understanding of others’ imperfections (Zhang et al., 2020), and even enhances our ability to forgive (Miyagawa & Taniguchi, 2022)." 
      },
      { 
        type: "text", 
        value: "When we are harsh on ourselves, we tend to deplete our emotional resources. By being kind to ourselves, we maintain more emotional energy to support others." 
      },
      { 
        type: "heading", 
        value: "<strong>Myth 3: Self-compassion is patronizing or belittling</strong>" 
      },
      { 
        type: "image",
        src: "/self-compassion4.jpg",
        alt: "Self-compassion image 4"   
      },
      { 
        type: "text", 
        value: "It’s easy to confuse self-compassion with treating oneself like a helpless child. But self-compassion is about giving ourselves the same understanding and kindness we’d extend to a close friend who is going through a difficult time." 
      },
      { 
        type: "text", 
        value: "Think of self-compassion as a dialogue between a wise, supportive coach and an athlete. The coach acknowledges the athlete’s strengths while also offering constructive feedback. The coach’s role is not to patronize but to support growth." 
      },
      { 
        type: "text", 
        value: "In my work as a therapist, I’ve found that self-compassion helps clients learn from their mistakes without becoming overwhelmed by them. It also allows people to be more present for those they care about and ultimately leads to a more meaningful life." 
      },
      { 
        type: "heading", 
        value: "<strong>Closing Thoughts</strong>" 
      },
      { 
        type: "text", 
        value: "I think that part of the reason that there is a natural resistance to self-compassion is that it's one of those concepts that seems almost too good to be true. The popular adage 'no pain, no gain' is one that leads us to dismiss self-compassion as something that is indulgent, unrealistic, and unproductive. In my work as a therapist, I’ve found precisely the opposite to be true. Self-compassion helps us to learn from our mistakes rather than becoming overwhelmed by them, it helps us to be more present for those we care about rather than being centered on our own suffering, and ultimately helps us to live a life that is more meaningful."
      },
      { 
        type: "heading", 
        value: "<strong>References</strong>" 
      },
      { 
        type: "text", 
        value: "*Lathren, C., Bluth, K., & Park, J. (2021). Self-compassion and interpersonal relationships: The role of emotion regulation in emerging adults. Journal of Adolescence, 90, 89-98.*" 
      },
      { 
        type: "text", 
        value: "*Miyagawa, Y., & Taniguchi, M. (2022). Self-compassion and learning from failure: The benefits of being kind to oneself. Psychological Reports, 125(1), 45-59.*" 
      },
      { 
        type: "text", 
        value: "*Neff, K. (2023). Self-Compassion.org. Retrieved from https://self-compassion.org*" 
      },
      { 
        type: "text", 
        value: "*Suh, S., & Chong, H. (2022). The effect of self-compassion on motivation and performance: Evidence from a randomized control trial. Motivation and Emotion, 46(4), 305-317.*" 
      },
      { 
        type: "text", 
        value: "*Zhang, Z., Li, T., & Wang, X. (2020). Self-compassion and the forgiveness of others: The role of empathy. Journal of Social Psychology, 160(5), 614-626.*" 
      }
    ]
  }
];

export default blogPosts;
