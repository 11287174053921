import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Import the hook for navigation
import "./styles/Services.css";

export default function Services() {
  const navigate = useNavigate(); // Initialize navigation function

  useEffect(() => {
    document.title = "Services - Aestus Health"; // Set the tab name when the component renders
  }, []); // Empty dependency array means this runs once when the component mounts

  const handleBookNowClick = () => {
    navigate("/contact"); // Navigate to the contact page when the button is clicked
  };

  return (
    <section className="services-section fade-in-page">
      <div className="services-content">
        <h2>Services</h2>
        <h3>Individual Therapy</h3>
        <p>
          I am excited to offer therapy
          services to individuals seeking support, growth, and healing. I offer
          1-hour individual counselling sessions that are conveniently
          accessible in-person, virtually and through phone. I am committed to providing a
          safe and compassionate space to help individuals explore their
          thoughts, emotions, and experiences. We are located at 3310 S Service Rd Burlington, ON.
        </p>
        <p>
          If you would like to work with me or would like to see if we would be a good fit, you are welcome to{' '}
          <a href="https://aestushealth.janeapp.com" target="_blank" rel="noopener noreferrer">
            book a free consultation call
          </a>.
        </p>
      </div>
      <div className="services-image">
        <img src="./personOnLaptop.jpg" alt="Therapy Session" />
      </div>
    </section>
  );
}
