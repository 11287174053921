import React, { useState } from "react";
import emailjs from "emailjs-com"; // Import the EmailJS library
import "./styles/ContactForm2.css";

export default function ContactForm2({ showImage = true, className = "" }) {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // EmailJS service parameters
    const serviceID = "service_mksrpyh";
    const templateID = "template_zjro2lc";
    const userID = "tCwPleql2f6kyBUuI";

    // Prepare the email data using formData
    const emailData = {
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
      message: formData.message,
    };

    // Send the email via EmailJS
    emailjs
      .send(serviceID, templateID, emailData, userID)
      .then((response) => {
        console.log("Email sent successfully!", response.status, response.text);
        alert("Your message has been sent successfully!");
      })
      .catch((error) => {
        console.error("Error sending email:", error);
        alert("There was an issue sending your message. Please try again.");
      });
  };

  return (
    <section className={`contact-section2 fade-in-page2 ${className}`}>
      <div className="form-container2">
        <h2>Contact</h2>
        <form className="contact-form2" onSubmit={handleSubmit}>
          <div className="form-group2">
            <label htmlFor="name">
              Name <span>*</span>
            </label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Your Name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group2">
            <label htmlFor="email">
              Email <span>*</span>
            </label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group2">
            <label htmlFor="phone">Phone Number</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              placeholder="Phone Number"
              value={formData.phone}
              onChange={handleChange}
            />
          </div>

          <div className="form-group2">
            <label htmlFor="message">Comment or Message</label>
            <textarea
              id="message"
              name="message"
              placeholder="Your Message"
              value={formData.message}
              onChange={handleChange}
            />
          </div>

          <button type="submit" className="submit-button2">
            Submit
          </button>
        </form>
      </div>

      {showImage && (
        <div className="contact-image2">
          <img src="/contact.jpg" alt="Contact" />
        </div>
      )}
    </section>
  );
}
