import React from "react";
import "./Anxiety.css";

export default function AnxietySection() {
  return (
    <section className="anxiety-section">
      <div className="text-section">
        <h2 className="cursive-heading">Understanding Anxiety</h2>
        <p>
          Do you often find yourself overwhelmed by worry, fear, or unease, even when there’s no immediate threat? Anxiety can feel like a constant companion, making everyday tasks and situations seem daunting. You might find yourself preoccupied with worst-case scenarios, struggling to control the flood of worry, or feeling a sense of dread that looms over your daily life. This persistent anxiety can make it difficult to focus, sleep, or enjoy activities that once brought you joy and meaning.
        </p>
        <div className="image-section-anxiety mobile-image">
          <img src="/anxiousPerson.jpg" alt="Anxious Person" />
        </div>
        <p>
          Anxiety is a widespread condition that significantly affects the lives of countless individuals around the world. Individuals experiencing severe anxiety often find themselves in a seemingly endless and isolating struggle. The intense emotions and persistent worries can create a sense of despair, making it difficult to envision a way out of the overwhelming distress. As anxiety escalates, feelings of isolation and hopelessness can intensify, leading to a vicious cycle of emotional turmoil. This, in turn, can create barriers that stop people from living a life that aligns with their values and aspirations, preventing them from living a fulfilling and purpose-driven existence.
        </p>
        <p>
          During these difficult moments, reaching out to an anxiety therapist can make a world of difference. In addition to providing a supportive environment for you to share your thoughts and feelings, a good therapist equips you with practical tools to manage your symptoms of anxiety effectively.
        </p>
        <p>
          At Aestus, I understand how challenging anxiety can be. My therapeutic approach focuses on providing you with a safe space to explore your anxiety, manage your relationship with it, and live a meaningful life.
        </p>
      </div>
      <div className="image-section-anxiety desktop-image">
        <img src="/anxiousPerson.jpg" alt="Anxious Person" />
      </div>
    </section>
  );
}
