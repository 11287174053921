import React from "react";
import "./Trauma.css";

export default function TraumaSection() {
  return (
    <section className="trauma-section">
      <div className="text-section">
        <h2 className="cursive-heading">Understanding Trauma</h2>
        <p>
          Trauma is the lasting emotional and psychological imprint left by deeply distressing or life-threatening experiences. It's not just about the event itself, but how it shakes a person's sense of safety, control, and trust in the world. Trauma can stem from a wide range of experiences—it could be a one-time event like a car accident or an ongoing situation like domestic abuse or childhood neglect.
        </p>
        <div className="image-section-trauma mobile-image">
          <img src="/traumaPerson.jpeg" alt="Trauma Person" />
        </div>
        <p>
          Everyone reacts to trauma differently, but some common signs include flashbacks, nightmares, anxiety, depression, trouble sleeping, or feeling constantly on edge. Trauma can also lead to self-destructive behaviors like substance abuse or self-harm. But it is worth remembering that trauma isn't a life sentence; healing is possible with the right support and care. Just as importantly, trauma isn't a sign of weakness. Trauma doesn't discriminate—it can happen to anyone, regardless of age, gender, or background.
        </p>
        <p>
          I offer trauma therapy for individuals at Aestus and recognize how challenging it can be to go through traumatic events. Trauma therapy is all about helping people find healing and hope after going through a deeply distressing or life-threatening experience. It's a safe, compassionate space where one can work through the pain and fear that can linger long after the event is over.
        </p>
        <p>
          Finally, it is worth mentioning that seeking help for trauma is a sign of strength. It takes courage to face painful experiences and work towards healing. You're more than welcome to <a href="https://aestushealth.janeapp.com/#staff_member/1" target="_blank" rel="noopener noreferrer">arrange a free consultation call</a> with me, and it would be my pleasure to join you on your path forward.
        </p>
      </div>
      <div className="image-section-trauma desktop-image">
        <img src="/traumaPerson.jpeg" alt="Trauma Person" />
      </div>
    </section>
  );
}
