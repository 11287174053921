import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './HamburgerMenu.css';

export default function HamburgerMenu() {
  const [isOpen, setIsOpen] = useState(false);
  const [isServicesDropdownOpen, setIsServicesDropdownOpen] = useState(false); // State for Services dropdown

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
    setIsServicesDropdownOpen(false); // Close the dropdown when menu closes
  };

  const toggleServicesDropdown = () => {
    setIsServicesDropdownOpen(!isServicesDropdownOpen);
  };

  return (
    <div>
      <button className={`hamburger ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
        {/* Change bars to "X" when the menu is open */}
        <div className={`bar top-bar ${isOpen ? 'rotate-top' : ''}`}></div>
        <div className={`bar middle-bar ${isOpen ? 'hide-middle' : ''}`}></div>
        <div className={`bar bottom-bar ${isOpen ? 'rotate-bottom' : ''}`}></div>
      </button>

      <nav className={`ham-menu ${isOpen ? 'open' : ''}`}>
        {/* Close button */}
        <button className="close-menu" onClick={toggleMenu}>
          &times;
        </button>
        <ul>
          <li><Link to="/#home" onClick={closeMenu}>Home</Link></li>
          <li><Link to="/contact" onClick={closeMenu}>Contact</Link></li>

          {/* Services Dropdown */}
          <li>
            <button className="dropdown-btn" onClick={toggleServicesDropdown}>
              Services
            </button>
            {isServicesDropdownOpen && (
              <ul className="dropdown-content2">
                <li><Link to="/angerManagementTherapy" onClick={closeMenu}>Anger Management Therapy</Link></li> 
                <li><Link to="/anxiety" onClick={closeMenu}>Anxiety Therapy</Link></li>
                <li><Link to="/BorderlinePersonalityDisorder" onClick={closeMenu}>Borderline Personality Disorder Therapy</Link></li>
                <li><Link to="/ChildrenTeenTherapy" onClick={closeMenu}>Children and Teen Therapy</Link></li>
                <li><Link to="/Depression" onClick={closeMenu}>Depression Therapy</Link></li>
                <li><Link to="/Grief" onClick={closeMenu}>Grief Therapy</Link></li>
                <li><Link to="/trauma" onClick={closeMenu}>Trauma Therapy</Link></li>



              </ul>
            )}
          </li>

          <li><Link to="/faq" onClick={closeMenu}>FAQ</Link></li>
          <li><Link to="/Blog" onClick={closeMenu}>Blog</Link></li>
        </ul>
      </nav>
    </div>
  );
}
