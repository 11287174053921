import React from "react";
import "./ChildrenTeenTherapy.css";

export default function ChildrenTeenTherapySection() {
  return (
    <section className="ChildrenTeenTherapy-section">
      <div className="text-section">
        <h2 className="cursive-heading">Child and Teen Therapy</h2>
        <p>
          I provide thoughtful and individualized therapy for children and teenagers, helping them to navigate the emotional ups and downs that come with growing up. I aim to provide a warm and inviting space where young people can openly express their thoughts and feelings without fear of judgment.
        </p>
        <div className="image-section-childrenTeenTherapy mobile-image">
        <img src="/childrenSmiling2.jpg" alt="Children Playing" />
      </div>
        <p>
          I understand the unique emotional and developmental needs they face and work closely with them to address a variety of concerns, including anxiety, behavioral changes, academic stress, social struggles, and emotional regulation. Using a range of child and teen-friendly therapeutic techniques, such as play therapy, art therapy, and talk therapy, my ultimate goal is to create a safe space where children and teenagers feel heard, understood, and supported.
        </p>
        <p>
          I believe in a holistic approach, involving parents and caregivers in the process whenever possible. This ensures that the therapy not only helps the child but also strengthens the family and supports a positive home environment. No matter what your child or teen is facing, I am here to help. If there's anything you'd like to know or if you're ready to take the next step,{" "}
          <a href="https://aestushealth.janeapp.com/#staff_member/1" target="_blank" rel="noopener noreferrer">
            you are welcome to reach out.
          </a>
        </p>
      </div>
      <div className="image-section-childrenTeenTherapy desktop-image">
        <img src="/childrenSmiling2.jpg" alt="Children Playing" />
      </div>
    </section>
  );
}
