import React from "react";
import { useNavigate } from "react-router-dom";
import blogPosts from "./blogData"; // Import blog data
import "./FeaturedBlog.css"; // Make sure to import the new CSS file

export default function FeaturedBlog() {
  const navigate = useNavigate();

  // Sort the blog posts by date (newest first)
  const sortedBlogs = blogPosts.sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  );

  // Navigate to the blog post based on ID
  const handleReadMoreClick = (id) => {
    navigate(`/blog/${id}`);
  };

  return (
    <section className="featured-blog">
      <h2>Blog Entries</h2>
      <div className="featured-blog-list">
        {sortedBlogs.map((blog) => (
          <div
            key={blog.id}
            className="blog-preview"
            onClick={() => handleReadMoreClick(blog.id)}
          >
            <img src={blog.image} alt={blog.title} className="blog-image" />
            <div className="blog-text">
              <h2 className="blog-title">{blog.title}</h2>
              <p>
                {blog.excerpt}{" "}
                <span
                  className="read-more-link"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent parent click handler from triggering
                    handleReadMoreClick(blog.id);
                  }}
                >
                  Read more
                </span>
              </p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
