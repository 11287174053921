import React from "react";
import "./Grief.css";

export default function GriefSection() {
  return (
    <section className="grief-section">
      <div className="text-section">
        <h2 className="cursive-heading">Understanding Grief</h2>
        <p>
          Grief can feel like a crushing wave of sadness, confusion, and disbelief, leaving you feeling lost and alone. Grief is a unique journey for everyone and can be extremely difficult to process alone.
        </p>
        <div className="image-section-grief mobile-image">
          <img src="/grievingPeople.jpg" alt="Grieving People" />
        </div>
        <p>
          Though grief is a natural response to loss, it can sometimes develop into a more difficult condition known as complicated grief. Unlike the typical grieving process, in which individuals gradually adapt to the loss and resume their daily functioning, complicated grief is characterized by an intensification of emotional pain and difficulty in accepting the loss of a loved one.
        </p>
        <p>
          When experiencing complicated grief, individuals may struggle with additional symptoms. These can include intense yearning for the person they lost, intrusive thoughts or memories, avoidance of reminders of the loss, feelings of guilt or anger, and a sense of disbelief or denial. Physical symptoms may involve changes in sleep patterns, appetite, and energy levels, as well as increased vulnerability to illness.
        </p>
        <p>
          I understand that going through grief is not a one-size-fits-all process. It is a deeply personal journey that looks different for each individual. In therapy, I aim to help you navigate the complex emotions of grief, find healthy ways to cope, and eventually heal. I would be honored to be part of your journey in healing from grief. Feel free to{" "}
          <a href="https://aestushealth.janeapp.com/#staff_member/1" target="_blank" rel="noopener noreferrer">
            book a free consultation call
          </a>{" "}
          with me to see if we would work well together.
        </p>
      </div>
      <div className="image-section-grief desktop-image">
        <img src="/grievingPeople.jpg" alt="Grieving People" />
      </div>
    </section>
  );
}
