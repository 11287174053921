import React from "react";
import "./Depression.css";

export default function DepressionSection() {
  return (
    <section className="depression-section">
      <div className="text-section">
        <h2 className="cursive-heading">Understanding Depression</h2>
        <p>
          Are you struggling with feelings of sadness, hopelessness, or a loss of interest in activities you once enjoyed? Depression can cast a heavy shadow on your life, making it difficult to find the motivation and energy to engage in daily tasks. You don’t have to suffer in silence – seeking help for depression can be the first step towards brighter days.
        </p>
        <div className="image-section-depression mobile-image">
          <img src="/depressedPerson.jpeg" alt="Depressed Person" />
        </div>
        <p>
          One of the most dangerous lies that depression tells us is that things are hopeless, convincing you that nothing will ever improve. It creates a distorted lens through which everything feels bleak, and any potential for change seems out of reach. This overwhelming sense of despair can make it hard to imagine a future where things are better, where joy, motivation, and hope return. But depression thrives on isolating you from the truth—things can change, and there is always the possibility of healing and growth, even if it feels impossible in the moment.
        </p>
        <p>
          At Aestus, I use evidence-based practices combined with a warm, caring approach to help you work through depression and rediscover hope, motivation, and joy in your life. You are welcome to <a href="https://aestushealth.janeapp.com/#staff_member/1" target="_blank" rel="noopener noreferrer">book a free consultation call</a> to see if we would be a good fit.
        </p>
      </div>
      <div className="image-section-depression desktop-image">
        <img src="/depressedPerson.jpeg" alt="Depressed Person" />
      </div>
    </section>
  );
}
