import React from "react";
import "./AngerManagementTherapy.css";

export default function AngerManagementTherapySection() {
  return (
    <section className="anger-management-section">
      <div className="text-section">
        <h2 className="cursive-heading">Understanding Anger</h2>
        <p>
          Do you often find yourself overwhelmed by anger, frustration, or irritability? Anger can be a powerful emotion, but when it becomes uncontrollable or destructive, it can negatively impact relationships, work, and your overall well-being. Learning to manage anger effectively can help you regain control and improve your emotional health.
        </p>
        <div className="image-section-anger mobile-image">
          <img src="/angryPerson.jpg" alt="Angry Person" />
        </div>
        <p>
          Anger often serves as a mask for other underlying emotions, such as hurt, fear, or frustration. While it's a normal human emotion, unmanaged anger can lead to impulsive actions, strained relationships, and a cycle of regret. Breaking free from this pattern involves understanding the root causes of your anger and developing healthier ways to cope and communicate.
        </p>
        <p>
          At Aestus, I use evidence-based practices and compassionate care to help you explore the sources of your anger and build effective strategies for managing it. I invite you to <a href="https://aestushealth.janeapp.com/#staff_member/1" target="_blank" rel="noopener noreferrer">book a free consultation call</a> if you would like to have support in dealing with anger.
        </p>
      </div>
      <div className="image-section-anger desktop-image">
        <img src="/angryPerson.jpg" alt="Angry Person" />
      </div>
    </section>
  );
}
